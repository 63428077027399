import React, { useMemo, useState } from 'react';
import { useFormikContext } from 'formik';
import Table from 'App/components/Table/Table';
import * as R from 'ramda';
import propOrDash from 'App/utils/propOrDash';
import DetailsModal from 'App/components/AlertsCenter/FinancialAssistance/DetailsModal';
import PropTypes from 'prop-types';
import selectActionCellRenderer from './selectActionCellRenderer';

const tableStateReducer = (state, action, prevState) => {
    const { type } = action;
    const initialSort = [{ id: 'programName', desc: false }];
    switch (type) {
        case 'resetSortBy':
            return prevState;
        case 'init':
            return R.compose(R.set(R.lensProp('sortBy'), initialSort), R.set(R.lensProp('pageSize'), 5))(state);
        case 'resetHiddenColumns':
            return { ...state, pageIndex: 0, pageSize: 5 };
        case 'resetPage':
            return R.isEmpty(prevState) ? { ...state, pageIndex: 0, pageSize: 5 } : prevState;
        case 'gotoPage':
        case 'toggleSortBy':
            return state;
        case 'setPageSize':
            return state;
        case 'setFilter':
            return { ...state, pageIndex: 0 };
        default:
            return R.isEmpty(prevState) ? state : prevState;
    }
};

function AvailableProgramsTable() {
    const { values, setFieldValue } = useFormikContext();
    const [showModalProgramId, setShowModalProgramId] = useState(null);

    const programId = values?.details?.programId;

    const columns = useMemo(
        () => [
            {
                Header: 'Program/Brand',
                accessor: propOrDash('programName'),
                id: 'programName',
            },
            {
                Header: 'Category',
                accessor: propOrDash('therapeuticAreas'),
            },
            {
                Header: 'Medications',
                accessor: propOrDash('drugs'),
            },
            {
                Header: 'Manufacturer',
                accessor: propOrDash('manufacture'),
            },
            {
                Header: 'Type',
                accessor: propOrDash('assistanceType'),
            },
            {
                Header: 'Eligibility',
                accessor: propOrDash('eligibility'),
            },
            {
                Header: 'Current Fund Level',
                accessor: propOrDash('foundationFundLevels'),
            },
            {
                Header: 'Actions',
                Cell: selectActionCellRenderer(setShowModalProgramId),
            },
        ],
        []
    );

    return (
        <>
            <Table
                columns={columns}
                data={values?.availablePrograms || []}
                pageSizeOptions={[5, 10, 25]}
                stateReducer={tableStateReducer}
            />

            {showModalProgramId && (
                <DetailsModal
                    programId={showModalProgramId}
                    onHide={() => {
                        setShowModalProgramId(null);
                    }}
                    showSelectBtn
                    onSelect={() => {
                        setFieldValue('details.programId', showModalProgramId);
                        setShowModalProgramId(null);
                    }}
                    isSelectDisabled={!!programId}
                />
            )}
        </>
    );
}

AvailableProgramsTable.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            programId: PropTypes.number,
            selected: PropTypes.bool,
        }),
    }),
};

AvailableProgramsTable.defaultProps = {
    row: {
        original: {
            programId: null,
            selected: false,
        },
    },
};

export default AvailableProgramsTable;
