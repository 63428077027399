import { getAllPrograms } from 'App/services/FinancialAssistanceService';
import { useQuery } from 'react-query';

function useFetchFAAllPrograms(patientId, referralId, financialAssistanceActivityId, typeId, medications, params) {
    const isMedicationsLoaded = Array.isArray(medications);
    const isMedicationValied = Boolean(medications?.length);
    const isDrugsValid = Boolean(params?.drugs?.trim());

    const shouldFetch =
        isMedicationsLoaded && ((isMedicationValied && isDrugsValid) || (!isMedicationValied && !isDrugsValid));

    return useQuery(
        ['patient', patientId, 'referral', referralId, 'activity', typeId, financialAssistanceActivityId, 'programs'],
        () => getAllPrograms(params),
        {
            retry: false,
            refetchInterval: false,
            refetchOnWindowFocus: false,
            enabled: shouldFetch,
        }
    );
}

export default useFetchFAAllPrograms;
