import toast from 'Lib/toast';
import errorHandler from 'App/utils/errorHandler';
import CompanyService from 'App/services/CompanyService';

/** @ngInject */
function UnassignedPatientController(_, $q, $state, $window, moment, NgTableParams, pesService) {
    const ctrl = this;
    ctrl.$onInit = $onInit;
    ctrl.associatePatient = associatePatient;
    ctrl.enrollPatient = enrollPatient;
    ctrl.ignorePatient = ignorePatient;

    function $onInit() {
        const { associationQueueId } = $state.params;
        return $q
            .all({
                associationEntry: pesService.getAssociationQueueEntry(associationQueueId),
                associations: pesService.getAssociations(associationQueueId),
                ehrSettings: CompanyService.getEhrBranchSettings(),
            })
            .then((res) => {
                const identifiers = res.associationEntry.message.Identifiers || [];
                const ehrIdentifier = identifiers.find((id) => id.IDType === res.ehrSettings.ehrPrimaryIdType);
                const primaryDispensingIntegration = res.associationEntry.message.OnlyUpdateIdentifiers || false;
                const isMckessonIntegration = res.associationEntry.integrationSource === 'TMS3';

                ctrl.patient = {
                    lastName: res.associationEntry.message.LastName,
                    firstName: res.associationEntry.message.FirstName,
                    gender: res.associationEntry.message.Gender,
                    birthDate: moment.utc(res.associationEntry.message.BirthDate).format('YYYY-MM-DD'),
                    addressLine1: res.associationEntry.message.Location.Address1,
                    city: res.associationEntry.message.Location.City,
                    state: res.associationEntry.message.Location.State,
                    zip: res.associationEntry.message.Location.Zip,
                    status: res.associationEntry.message.Status,
                    primaryDispensingIntegration,
                    isMckessonIntegration,
                    ehrId: ehrIdentifier ? ehrIdentifier.ID : '--',
                };

                const associations = _.map(res.associations, (entry) => {
                    // TODO: Fix this the next time the file is edited.
                    // eslint-disable-next-line no-param-reassign
                    entry.birthDate = moment.utc(entry.birthDate).utc().format('YYYY-MM-DD');
                    return entry;
                });
                ctrl.tableParams = new NgTableParams(
                    {
                        count: 5,
                    },
                    {
                        counts: [5, 10, 25],
                        dataset: associations,
                    }
                );
            })
            .catch(errorHandler);
    }

    function associatePatient(id) {
        pesService
            .associatedPatientAction('associated', $state.params.associationQueueId, id)
            .then(() => {
                toast.success('Patient has been associated');
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line no-param-reassign
                $window.location = '/#!/workQueue';
            })
            .catch(errorHandler);
    }

    function ignorePatient() {
        pesService
            .associatedPatientAction('deleted', $state.params.associationQueueId, '')
            .then(() => {
                toast.success('Patient has been ignored');
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line no-param-reassign
                $window.location = '/#!/workQueue';
            })
            .catch(errorHandler);
    }

    function enrollPatient() {
        pesService
            .associatedPatientAction('enrolled', $state.params.associationQueueId, '')
            .then(() => {
                toast.success('Patient has been enrolled');
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line no-param-reassign
                $window.location = '/#!/workQueue';
            })
            .catch(errorHandler);
    }
}

export default UnassignedPatientController;
