import dayjs from 'dayjs';

export const defaultActivityDateModalState = {
    show: false,
    onHide: () => {},
    activity: {
        id: '',
        dateDue: null,
        typeLabel: '',
        patientId: '',
    },
};

export const defaultActivityNoteModalState = {
    show: false,
    onHide: () => {},
    activity: {
        id: '',
        typeLabel: '',
        name: '',
    },
    patient: {
        firstName: '',
        lastName: '',
    },
};

export const defaultWorkQueueState = {
    filterParams: {
        search: {},
        dateEnd: new Date(dayjs().endOf('isoWeek')),
        dateStart: new Date(dayjs().startOf('isoWeek')),
        type: 'this_week',
        branches: [],
        highRisk: false,
        onlyMine: false,
        allTypes: false,
        categories: [],
        allBranches: true,
        assignedToMe: false,
        filters: [],
        predictiveModelRisk: false,
        allCategories: true,
        page: 1,
        count: 25,
        sorting: {
            patient_name: 'asc',
        },
    },
    result: {
        savedFilterResult: [],
        activityCountResult: {},
        workQueueTableHeaders: {},
        workQueueActivityCount: 0,
        workQueueResult: [],
        unassignedPatientResult: [],
        unassignedMedicationResult: [],
    },
    isLoading: true,
    modalState: {
        activityDateModal: defaultActivityDateModalState,
        activityNoteModal: defaultActivityNoteModalState,
    },
    selectedRows: {},
};

export const getDefaultWorkQueueState = () => ({
    filterParams: {
        search: {},
        dateEnd: new Date(dayjs().endOf('isoWeek')),
        dateStart: new Date(dayjs().startOf('isoWeek')),
        type: 'this_week',
        branches: [],
        highRisk: false,
        onlyMine: false,
        allTypes: false,
        categories: [],
        allBranches: true,
        assignedToMe: false,
        filters: [],
        predictiveModelRisk: false,
        allCategories: true,
        page: 1,
        count: 25,
        sorting: {
            patient_name: 'asc',
        },
    },
    result: {
        savedFilterResult: [],
        activityCountResult: {},
        workQueueTableHeaders: {},
        workQueueActivityCount: 0,
        workQueueResult: [],
        unassignedPatientResult: [],
        unassignedMedicationResult: [],
    },
    isLoading: true,
    modalState: {
        activityDateModal: defaultActivityDateModalState,
        activityNoteModal: defaultActivityNoteModalState,
    },
    modalMobileAnswersState: {
        mobileAnswersActivityDateModal: defaultActivityDateModalState,
        mobileAnswersActivityNoteModal: defaultActivityNoteModalState,
    },
    selectedRows: {},
});
