import { PERMISSION_TYPES } from 'App/utils/isPermitted/isPermitted';
import * as R from 'ramda';
import hasLength from 'App/utils/hasLength';
import toast from 'Lib/toast';
import getFeatureFlagStatus from 'App/utils/getFeatureFlagStatus';

const activityInProgressStatus = {
    0: ['Pending', 'Created', 'New'],
    1: ['Pending', 'Created', 'New'],
    4: ['Pending', 'Created'],
    2: ['Pending', 'Pending Submission', 'Created', 'Submitted', 'New'],
    5: ['Pending', 'Pending Submission', 'Created', 'Submitted'],
    3: ['Pending', 'Pending Submission', 'Created', 'Submitted'],
};

const REFERRAL_ACTIVITY_TYPES = Object.freeze({
    0: { value: 0, label: 'Benefits Investigation' },
    1: { value: 1, label: 'Prior Authorization' },
    2: { value: 2, label: 'Financial Assistance' },
    3: { value: 3, label: 'Appeal' },
    4: { value: 4, label: 'Prior Authorization Renewal' },
    5: { value: 5, label: 'Financial Assistance Renewal' },
});

/** @ngInject */
function ReferralAddActivityModalController(_, rmsService) {
    const ctrl = this;

    ctrl.$onChanges = $onChanges;
    ctrl.$onInit = $onInit;
    ctrl.doChangeType = doChangeType;
    ctrl.submit = submit;
    ctrl.referralProtocolManagerEnabled = false;
    ctrl.showReferralWarningMessage = false;
    ctrl.rawActivities = [];
    ctrl.disableSubmitButton = true;

    function $onInit() {
        ctrl.ngModelOptions = {
            updateOn: 'default blur',
            debounce: {
                default: 1000,
                blur: 0,
            },
        };

        ctrl.medication = null;
        ctrl.disableSubmitButton = true;
        ctrl.type = null;
    }

    function $onChanges() {
        if (ctrl.resolve) {
            if (ctrl.resolve.user) {
                ctrl.user = angular.copy(ctrl.resolve.user);
            }

            /**
             * Activities is an object keyed by types where the values is
             * and array of medication IDs
             */
            ctrl.activities = angular.copy(ctrl.resolve.activities);
            ctrl.rawActivities = angular.copy(ctrl.resolve.rawActivities);
            ctrl.medications = angular.copy(ctrl.resolve.medications);
            ctrl.referral = angular.copy(ctrl.resolve.referral);
            ctrl.deniedPriorAuthorizations = angular.copy(ctrl.resolve.deniedPriorAuthorizations);
            ctrl.approvedFinancialAssistances = angular.copy(ctrl.resolve.approvedFinancialAssistances);
            ctrl.approvedPriorAuthorizations = angular.copy(ctrl.resolve.approvedPriorAuthorizations);

            ctrl.validPriorAuthorizations = _.filter(ctrl.deniedPriorAuthorizations, (denial) => {
                return ctrl.activities[3].indexOf(denial.id) === -1;
            });

            ctrl.referralProtocolManagerEnabled = getFeatureFlagStatus(
                PERMISSION_TYPES.FEATURE_REFERRAL_PROTOCOL_MANAGER
            );

            ctrl.newRenewalActivityTypesEnabled = getFeatureFlagStatus(
                PERMISSION_TYPES.FEATURE_NEW_RENEWAL_ACTIVITY_TYPES
            );

            ctrl.activityTypes = R.compose(
                R.ifElse(
                    R.always(hasLength(ctrl.validPriorAuthorizations)),
                    R.assoc(3, REFERRAL_ACTIVITY_TYPES[3]),
                    R.identity
                ),
                R.ifElse(
                    R.always(ctrl.newRenewalActivityTypesEnabled && ctrl.referralProtocolManagerEnabled),
                    R.compose(R.assoc(4, REFERRAL_ACTIVITY_TYPES[4]), R.assoc(5, REFERRAL_ACTIVITY_TYPES[5])),
                    R.identity
                ),
                R.pick([0, 1, 2])
            )(REFERRAL_ACTIVITY_TYPES);

            ctrl.validFinancialAssistances = _.filter(ctrl.approvedFinancialAssistances, (a) => {
                // where the medication id is not found
                return ctrl.activities[5].indexOf(a.id) === -1;
            });

            ctrl.validApprovedPriorAuthorizations = _.filter(ctrl.approvedPriorAuthorizations, (a) => {
                // where the medication id is not found
                return ctrl.activities[4].indexOf(a.id) === -1;
            });
        }
    }

    function _handleChangeTheOldWay() {
        ctrl.validMedications = _.filter(ctrl.medications, (m) => {
            return ctrl.activities[ctrl.type.value].indexOf(m.medication_id) === -1;
        });
        ctrl.showMedications = ctrl.validMedications.length > 0;
        ctrl.disableSubmitButton = ctrl.validMedications.length <= 0;
    }

    function _handleChangeTheNewWay() {
        const activityInProgress = ctrl.rawActivities.find((it) => {
            return it.type === Number(ctrl.type.value) && activityInProgressStatus[ctrl.type.value].includes(it.status);
        });

        ctrl.showReferralWarningMessage = !!activityInProgress?.id;
        if (ctrl.type) {
            ctrl.disableSubmitButton = false;
            ctrl.medication = ctrl?.referral?.medications.map(R.pick(['medication_id'])) || [];
        }
    }

    function doChangeType() {
        ctrl.medication = null;
        ctrl.addActivityForm.$setPristine();
        ctrl.addActivityForm.$setUntouched();

        if (ctrl.referralProtocolManagerEnabled) {
            _handleChangeTheNewWay();
        } else {
            _handleChangeTheOldWay();
        }
    }

    async function submit() {
        if (ctrl.typeMessage) {
            return;
        }

        ctrl.saving = true;

        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line consistent-return
        return rmsService
            .createActivity(ctrl.referral.patient_id, ctrl.referral.id, {
                type: ctrl.type.value,
                medication: ctrl.medication,
                details: ctrl.details || {},
            })
            .then((res) => {
                toast.success('The referral activity was created successfully.');
                ctrl.close({ $value: res });
                return res;
            })
            .catch((res) => {
                if (res.status === 404 || res.status === 403) {
                    toast.error(
                        'The referral was not found or you do not have permission to access it. ' +
                            'Please contact your STM administrator.'
                    );
                } else if (!res.status) {
                    toast.error(res);
                } else {
                    toast.error('Referral activity was not updated successfully. Please try again.');
                }
                ctrl.dismiss({ $value: ctrl.resolve });
                return res;
            })
            .finally(() => {
                ctrl.saving = false;
            });
    }
}

export default ReferralAddActivityModalController;
