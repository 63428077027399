import React from 'react';
import ActionCell, { ActionButtonLink } from 'App/components/styles/ActionCell';

const propTypes = {};

const defaultProps = {};

function UserActionsCell() {
    return (
        <ActionCell>
            <ActionButtonLink>Edit</ActionButtonLink>
            <ActionButtonLink>Disable</ActionButtonLink>
        </ActionCell>
    );
}

UserActionsCell.propTypes = propTypes;
UserActionsCell.defaultProps = defaultProps;

export default UserActionsCell;
