import { cx } from '@emotion/css';
import PropTypes from 'prop-types';
import React from 'react';
import { ControlLabel, FormGroup } from 'react-bootstrap';

const customElementPropTypes = { isFieldset: PropTypes.bool.isRequired };
const FormGroupElement = ({ isFieldset, ...rest }) => (isFieldset ? <fieldset {...rest} /> : <FormGroup {...rest} />);
FormGroupElement.propTypes = customElementPropTypes;
const ControlLabelElement = ({ isFieldset, ...rest }) =>
    isFieldset ? <legend {...rest} /> : <ControlLabel {...rest} />;
ControlLabelElement.propTypes = customElementPropTypes;

const FieldLabel = ({ fieldName, label, children, required, suppressAsterisk, hasError, isFieldset }) => {
    return (
        <FormGroupElement
            isFieldset={isFieldset}
            className={cx('form-group', {
                'has-error': hasError,
            })}
        >
            <ControlLabelElement
                isFieldset={isFieldset}
                htmlFor={fieldName}
                className={cx('control-label', {
                    'field-required': required,
                    'suppress-asterisk': suppressAsterisk,
                })}
            >
                {label}
            </ControlLabelElement>
            {children}
        </FormGroupElement>
    );
};

FieldLabel.propTypes = {
    fieldName: PropTypes.string.isRequired,
    label: PropTypes.node.isRequired,
    children: PropTypes.node.isRequired,
    required: PropTypes.bool,
    suppressAsterisk: PropTypes.bool,
    hasError: PropTypes.bool,
    isFieldset: PropTypes.bool,
};

FieldLabel.defaultProps = {
    required: false,
    suppressAsterisk: false,
    hasError: false,
    isFieldset: false,
};

export default FieldLabel;
