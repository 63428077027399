import styled from '@emotion/styled';
import { camelCase, groupBy } from 'lodash';
import React from 'react';
import { Link, generatePath, useLocation } from 'react-router-dom';

import { contentMonographRoute, editPatientRoute, patientRoute } from 'App/common/routeLookup';
import PatientDisplayId from 'App/components/PatientDisplayId/PatientDisplayId';
import RxFillNumberSideBar from 'App/components/PatientPrescription/RxFillNumberSideBar';
import PatientEngagement from 'App/components/PatientProfile/components/PatientEngagement/PatientEngagement';
import useGetPatientSubscriptions from 'App/components/PatientProfile/hooks/useGetPatientSubscriptions';
import useFetchPatientLabels from 'App/hooks/useFetchPatientLabels';
import useFetchPatientMedications from 'App/hooks/useFetchPatientMedications';
import useFetchPatientOtherDiagnoses from 'App/hooks/useFetchPatientOtherDiagnoses';
import useFetchPatientOtherMedications from 'App/hooks/useFetchPatientOtherMedications';
import useFetchPatientPredictiveModelRisk from 'App/hooks/useFetchPatientPredictiveModelRisk';
import { dateOnlySlashesFormat, formatUtcDate } from 'App/services/DateService';
import { formatPhone, isExpired } from 'App/utils';
import useIsPermitted from 'App/utils/hooks/useIsPermitted';
import { PERMISSION_TYPES } from 'App/utils/isPermitted/isPermitted';
import { patientPropType } from 'Lib/types';
import PatientHighRiskIndicator from '../../PatientHighRiskIndicator/PatientHighRiskIndicator';
import { PatientPredictiveModelRiskIndicator, PatientPredictiveModelRiskText } from '../../PatientPredictiveModelRisk';
import useFetchPatientAllergies from '../hooks/useFetchPatientAllergies';
import useFetchPatientICD10 from '../hooks/useFetchPatientICD10';
import PatientCardSectionLabel from './PatientCardSectionLabel';
import PatientNotes from './PatientNotes';

const SidebarStyle = styled.div`
    background-color: #f1f6f9;
    padding: 20px;
    width: 300px;
    flex-shrink: 0;
    overflow: auto;
`;

const PatientName = styled.div`
    font-size: 18px;
`;

const BottomGutter = styled.div`
    margin-bottom: 10px;
`;

const PatientCardSection = styled.div`
    margin: 20px 0;

    & .engage-label {
        color: var(--manatee);
        font-weight: bold;
    }
`;

const PatientCardLabel = styled.span`
    font-size: 12px;
    color: var(--manatee);
`;

const LeftGutterStyle = styled.div`
    padding-left: 1.5em;
`;

const PredictiveModelTextStyledDiv = styled.div`
    font-size: 12px;
    background-color: #fff;
    border-radius: 4px;
    display: block;
    margin: 10px 0;
    padding: 2px;
`;

const AssessmentSidebar = ({ patient }) => {
    const location = useLocation();
    const patientPredictiveModelEnabled = useIsPermitted(PERMISSION_TYPES.PATIENT_PREDICTIVE_MODEL);
    const { data: icd10s } = useFetchPatientICD10(patient.id);
    const { data: patientMedications } = useFetchPatientMedications(patient.id);
    const { data: allergies } = useFetchPatientAllergies(patient.id);
    const { data: otherDiagnoses } = useFetchPatientOtherDiagnoses(patient.id);
    const otherDiagnosesList = Object.values(otherDiagnoses || []);
    const { data: otherMedications } = useFetchPatientOtherMedications(patient.id);
    const otherMedicationsList = Object.values(otherMedications || []);
    const { data: patientLabels } = useFetchPatientLabels(patient.id);
    const patientLabelsList = Object.values(patientLabels || []);
    const patientSubscriptions = useGetPatientSubscriptions(patient.id);
    const { patientMessagingSubscription, triggerSubscriptionRefetch } = patientSubscriptions;
    const { data: predictiveModel } = useFetchPatientPredictiveModelRisk(patient.id, patientPredictiveModelEnabled);
    const hasPredictiveModelRiskData = predictiveModel?.length > 0;

    return (
        <SidebarStyle className="assessment-sidebar">
            <PatientName>
                <Link
                    to={{ pathname: patient.id && generatePath(patientRoute, { patientId: patient.id }) }}
                    style={{ marginRight: '10px' }}
                >
                    {patient.lastName}, {patient.firstName}
                </Link>
                {patient.highRisk && <PatientHighRiskIndicator patientId={patient.id} />}
                {hasPredictiveModelRiskData && (
                    <>
                        <PatientPredictiveModelRiskIndicator riskSegments={predictiveModel} />
                        <PredictiveModelTextStyledDiv>
                            <PatientPredictiveModelRiskText riskSegments={predictiveModel} />
                        </PredictiveModelTextStyledDiv>
                    </>
                )}
            </PatientName>

            <div>
                {patient.preferredName && `"${patient.preferredName}" `}
                {patient.pronouns && `(${patient.pronouns})`}
            </div>

            <BottomGutter>{patient.id && <PatientDisplayId patientId={patient.id} />}</BottomGutter>
            <Link
                to={{
                    pathname: patient.id && generatePath(editPatientRoute, { patientId: patient.id }),
                    search: `redirectUrl=${location.pathname}`,
                }}
            >
                Edit Patient
            </Link>

            <PatientCardSection>
                <PatientCardSectionLabel>Demographics</PatientCardSectionLabel>

                <div>
                    <PatientCardLabel>Status</PatientCardLabel> <span>{patient.status || '--'}</span>
                    <span>{formatUtcDate({ date: patient.deceasedDate, format: dateOnlySlashesFormat })}</span>
                </div>
                <div>
                    <PatientCardLabel>Sex</PatientCardLabel> <span>{patient.gender}</span>
                </div>
                {patient.genderIdentity && (
                    <div>
                        <PatientCardLabel>Gender Identity</PatientCardLabel> <span>{patient.genderIdentity}</span>
                    </div>
                )}
                <div>
                    <PatientCardLabel>DOB</PatientCardLabel>{' '}
                    <span>{formatUtcDate({ date: patient.birthDate, format: dateOnlySlashesFormat })}</span>
                </div>
                <div>
                    <PatientCardLabel>Height</PatientCardLabel>{' '}
                    <span>{patient.height ? `${patient.height} in` : '--'}</span>
                </div>
                <div>
                    <PatientCardLabel>Weight</PatientCardLabel>{' '}
                    <span>{patient.weight ? `${patient.weight} lbs` : '--'}</span>
                </div>
            </PatientCardSection>

            <PatientCardSection>
                <PatientCardSectionLabel>Contact Information</PatientCardSectionLabel>

                <BottomGutter>
                    <PatientCardLabel>Address</PatientCardLabel>
                    <div>
                        {patient.addressLine1} {patient.addressLine2}
                    </div>
                    <div>
                        {patient.city}, {patient.state} {patient.zip}
                    </div>
                </BottomGutter>

                <BottomGutter>
                    <PatientCardLabel>Preferred Language</PatientCardLabel>
                    <div>{patient.languageSpoken || '--'}</div>
                </BottomGutter>

                <div>
                    <PatientCardLabel>H</PatientCardLabel> {formatPhone(patient.homePhone) || '--'}
                    {patient.homePhone && patient.preferredContactMethod === 'Home Phone' && ' (preferred)'}
                </div>
                <div>
                    <PatientCardLabel>M</PatientCardLabel> {formatPhone(patient.mobilePhone) || '--'}
                    {patient.mobilePhone && patient.preferredContactMethod === 'Mobile Phone' && ' (preferred)'}
                </div>
                <div>
                    <PatientCardLabel>W</PatientCardLabel> {formatPhone(patient.workPhone) || '--'}
                    {patient.workPhone && patient.preferredContactMethod === 'Work Phone' && ' (preferred)'}
                </div>
                <div>
                    <PatientCardLabel>E</PatientCardLabel> {patient.email || '--'}
                    {patient.email && patient.preferredContactMethod === 'Email' && ' (preferred)'}
                </div>
            </PatientCardSection>

            {!icd10s ? null : (
                <PatientCardSection>
                    <PatientCardSectionLabel>Diagnoses</PatientCardSectionLabel>
                    {icd10s.length === 0
                        ? '--'
                        : icd10s.map((icd10) => (
                              <div key={icd10.icd10Details.DxPk}>
                                  {icd10.icd10Details.DxCode} {icd10.icd10Details.DxDescShort}
                              </div>
                          ))}
                </PatientCardSection>
            )}

            <PatientCardSection>
                <PatientCardSectionLabel>Medications</PatientCardSectionLabel>
                {Object.values(groupBy(patientMedications, 'medication.name'))
                    .filter((medList) => !isExpired(medList[0].status[0]?.end))
                    .map((medList) => (
                        <div key={medList[0].medication.id}>
                            <Link
                                id={`resource-link-${medList[0].medication.id}`}
                                to={generatePath(contentMonographRoute, {
                                    label: camelCase(medList[0].medication.name),
                                })}
                                target="_blank"
                            >
                                {medList[0].medication.name}
                            </Link>

                            {medList.map((patientMedication) => (
                                <LeftGutterStyle
                                    key={patientMedication.id}
                                    id={`patient-medication-${patientMedication.medication.id}-info`}
                                >
                                    <div>
                                        <PatientCardLabel>Medication Start </PatientCardLabel>
                                        {!patientMedication.status[0]?.start
                                            ? '--'
                                            : formatUtcDate({
                                                  date: patientMedication.status[0].start,
                                                  format: dateOnlySlashesFormat,
                                              })}
                                    </div>
                                    <RxFillNumberSideBar
                                        patientId={patient.id}
                                        medication={patientMedication.medication}
                                    />
                                </LeftGutterStyle>
                            ))}
                        </div>
                    ))}
            </PatientCardSection>

            <PatientCardSection>
                <PatientNotes patient={patient} />
            </PatientCardSection>

            {!allergies ? null : (
                <PatientCardSection>
                    <PatientCardSectionLabel>Allergies</PatientCardSectionLabel>
                    {!!Number(patient.noKnownAllergies) && 'No Known Allergies'}
                    {!allergies.length && !Number(patient.noKnownAllergies) && '--'}
                    {allergies.map((allergy, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <div key={index}>{allergy}</div>
                    ))}
                </PatientCardSection>
            )}

            {!otherDiagnoses ? null : (
                <PatientCardSection>
                    <PatientCardSectionLabel>Other Diagnoses</PatientCardSectionLabel>
                    {!otherDiagnosesList.length
                        ? '--'
                        : otherDiagnosesList.map((otherDiagnosis) => (
                              <div key={otherDiagnosis.id}>{otherDiagnosis.text}</div>
                          ))}
                </PatientCardSection>
            )}

            <PatientCardSection>
                <PatientCardSectionLabel>Other Medications</PatientCardSectionLabel>
                {otherMedications && !otherMedicationsList.length
                    ? '--'
                    : otherMedicationsList.map((otherMedication) => (
                          <div key={otherMedication.id}>
                              <div>{otherMedication.text}</div>
                              <LeftGutterStyle>
                                  <div>
                                      <PatientCardLabel>Route</PatientCardLabel> {otherMedication.routeOfAdmin || '--'}
                                  </div>
                                  <div>
                                      <PatientCardLabel>Strength</PatientCardLabel> {otherMedication.dose || '--'}
                                  </div>
                                  <div>
                                      <PatientCardLabel>Frequency</PatientCardLabel> {otherMedication.frequency || '--'}
                                  </div>
                              </LeftGutterStyle>
                          </div>
                      ))}
            </PatientCardSection>

            <PatientCardSection>
                <PatientCardSectionLabel>Labels</PatientCardSectionLabel>
                {patientLabels && !patientLabelsList.length
                    ? '--'
                    : patientLabelsList.map((patientLabel) => <div key={patientLabel.id}>{patientLabel.label}</div>)}
            </PatientCardSection>

            <PatientCardSection>
                <PatientEngagement
                    patientDemographicsData={patient}
                    patientMessagingSubscription={patientMessagingSubscription}
                    refetchMessagingSubscriptions={triggerSubscriptionRefetch}
                />
            </PatientCardSection>
        </SidebarStyle>
    );
};

AssessmentSidebar.propTypes = {
    patient: patientPropType.isRequired,
};

export default AssessmentSidebar;
