import { useQuery } from 'react-query';
import CompanyService from 'App/services/CompanyService';
import * as R from 'ramda';

function useFetchCompanyUsers() {
    return useQuery(['company', 'users'], {
        queryFn: () => CompanyService.getCompanyUsers().then(R.values),
        retry: false,
        refetchInterval: false,
        refetchOnWindowFocus: false,
    });
}

export default useFetchCompanyUsers;
