import { useQuery } from 'react-query';
import CompanyService from 'App/services/CompanyService';
import * as R from 'ramda';

function useFetchDisabledCompanyUsers() {
    return useQuery(['company', 'users', 'disabled'], {
        queryFn: () => CompanyService.getCompanyUsers({ disabledOnly: true }).then(R.values),
        retry: false,
        refetchInterval: false,
        refetchOnWindowFocus: false,
    });
}

export default useFetchDisabledCompanyUsers;
