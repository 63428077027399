import {
    addAlertSetting,
    addAlertUser,
    deleteAlertUser,
    getAlertCompanySettings,
    getAlertSettings,
    getAlertUsers,
    updateAlertCompanySetting,
    updateAlertSetting,
} from 'App/services/AlertService';
import CompanyService from 'App/services/CompanyService';
import { getAllPrograms } from 'App/services/FinancialAssistanceService';
import { errorHandler } from 'App/utils';
import * as R from 'ramda';
import toast from 'Lib/toast';
import { assign, createMachine } from 'xstate';
import * as Yup from 'yup';

//#region Helpers

/**
 * Diff the alert settings to find what settings have changed, and what settings have added.
 * There is no delete for alert settings.
 * @param original
 * @param current
 * @return {*|number|(function(*=, *=): (*|number))|(function(*=): (*|number))}
 */
const getAlertSettingDiff = (original, current) =>
    R.reduce(
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-shadow
        (result, current) => {
            const exists = R.prop(current.programId, original);
            if (exists && !Object.is(exists, current)) {
                // changes detected
                return R.over(R.lensProp('changed'), R.append(current), result);
            }

            if (!exists) {
                // new item
                return R.over(R.lensProp('added'), R.append(current), result);
            }

            return result;
        },
        { changed: [], added: [] },
        R.values(current)
    );

/**
 * Diff the users to find out who was added and who was removed.
 * There is no change/update.
 * @param original
 * @param current
 * @return {{removed: (*|number|(function(*=): (*|number))), added: (*|number|(function(*=): (*|number)))}}
 */
const getUserDiff = (original, current) => {
    const removed = R.difference(original, current);
    const added = R.difference(current, original);
    return {
        removed,
        added,
    };
};

const mapAlertSetting = R.compose(
    R.applySpec({
        programId: R.prop('programId'),
        uuid: R.prop('uuid'),
        open: R.compose(R.ifElse(R.identity, R.always(1), R.always(0)), R.prop('open')),
        close: R.compose(R.ifElse(R.identity, R.always(1), R.always(0)), R.prop('close')),
    }),
    R.pick(['uuid', 'programId', 'open', 'close'])
);

//#endregion

const States = Object.freeze({
    DETAILS_SHOWING: 'DETAILS_SHOWING',
    FAILED: 'FAILED',
    LOADED: 'LOADED',
    LOADING: 'LOADING',
    SAVING: 'SAVING',
    VALIDATING: 'VALIDATING',
});

const Events = Object.freeze({
    FAILURE: 'FAILURE',
    HIDE_DETAILS: 'HIDE_DETAILS',
    SAVE: 'SAVE',
    SELECT_USERS: 'SELECT_USERS',
    SHOW_DETAILS: 'SHOW_DETAILS',
    SUCCESS: 'SUCCESS',
    TOGGLE_EMAIL: 'TOGGLE_EMAIL',
    TOGGLE_OPTION: 'TOGGLE_OPTION',
});

/**
 * Create a collection of event matchers
 */
const stateMatchers = {
    isLoading: R.anyPass([R.equals(States.LOADING)]),
    isDetailsShowing: R.equals(States.DETAILS_SHOWING),
};

const validationSchema = Yup.object().shape({
    users: Yup.object().shape({
        current: Yup.array()
            .nullable()
            .min(1, 'You must choose at least one user to receive alerts.')
            .required('You must choose at least one user to receive alerts.'),
    }),
});

const machine = createMachine(
    {
        id: 'AlertManagerFinancialAssistance',
        initial: States.LOADING,
        context: {
            isDirty: false,
            programs: [],
            modalProgramId: null,
            errors: null,
            availableOptions: {
                users: [],
            },
            users: {
                current: [],
                original: [],
            },
            companySettings: {
                email: false,
            },
            alertSettings: {
                current: {},
                original: {},
            },
        },
        states: {
            [States.LOADING]: {
                invoke: {
                    id: 'getInitialData',
                    src: 'getInitialData',
                    onDone: {
                        target: States.LOADED,
                        actions: ['setInitialData'],
                    },
                    onError: {
                        target: States.FAILED,
                        actions: ['handleError'],
                    },
                },
            },
            [States.LOADED]: {
                on: {
                    [Events.TOGGLE_EMAIL]: { actions: ['toggleEmail', 'markDirty'] },
                    [Events.SELECT_USERS]: { actions: ['selectUsers', 'markDirty'] },
                    [Events.TOGGLE_OPTION]: { actions: ['toggleOption', 'markDirty'] },
                    [Events.SHOW_DETAILS]: {
                        target: States.DETAILS_SHOWING,
                        actions: ['showDetails'],
                    },
                    [Events.SAVE]: { target: States.VALIDATING },
                },
            },
            [States.DETAILS_SHOWING]: {
                on: {
                    [Events.HIDE_DETAILS]: { target: States.LOADED, actions: ['hideDetails'] },
                },
            },
            [States.VALIDATING]: {
                invoke: {
                    src: 'validate',
                    onDone: {
                        target: States.SAVING,
                        actions: ['clearErrors'],
                    },
                    onError: {
                        target: States.LOADED,
                        actions: ['validationError'],
                    },
                },
            },
            [States.SAVING]: {
                invoke: {
                    id: 'saving',
                    src: 'save',
                    onDone: {
                        target: States.LOADING,
                        actions: ['resetDirty'],
                    },
                    onError: {
                        target: States.LOADED,
                        actions: ['handleError'],
                    },
                },
            },
            [States.FAILED]: {
                type: 'final',
                actions: ['handleError'],
            },
        },
    },
    {
        actions: {
            markDirty: assign({
                isDirty: R.T,
            }),
            resetDirty: assign({
                isDirty: R.F,
            }),
            toggleOption: assign((context, event) => {
                return R.compose(
                    /**
                     * A Lens will add the property if it doesn't already exist. As such, if we toggle an option
                     * that does not exist in our `current` settings, it will add it without the program ID. This
                     * will also ensure that the program ID is included in _all_ cases.
                     */
                    R.set(R.lensPath(['alertSettings', 'current', event.programId, 'programId']), event.programId),
                    R.over(R.lensPath(['alertSettings', 'current', event.programId, event.prop]), R.not)
                )(context);
            }),
            setInitialData: assign((context, event) => {
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line prefer-const
                let [programs, users, companyUsers, alertSettings, companySettings] = event.data;

                /**
                 * Associate the already assigned users (users) with the available users  (company users)
                 */
                users = R.compose(
                    R.map((user) => R.prop(user.userId, R.indexBy(R.prop('id'), companyUsers))),
                    R.values
                )(users);

                const availableUsers = companyUsers;

                return R.compose(
                    R.set(R.lensProp('companySettings'), companySettings),
                    R.set(R.lensProp('programs'), programs),
                    R.set(R.lensPath(['availableOptions', 'users']), availableUsers),
                    /**
                     * Store two copies so we can do change detection later and find out what needs
                     * created vs. what needs updated.
                     */
                    R.set(R.lensPath(['alertSettings', 'current']), alertSettings),
                    R.set(R.lensPath(['alertSettings', 'original']), alertSettings),
                    R.set(R.lensPath(['users', 'current']), users),
                    R.set(R.lensPath(['users', 'original']), users)
                )(context);
            }),
            toggleEmail: assign((context) => {
                return R.over(R.lensPath(['companySettings', 'email']), R.not, context);
            }),
            selectUsers: assign((context, event) => {
                return R.set(R.lensPath(['users', 'current']), event.users, context);
            }),
            setData: assign((context, event) => {
                return R.set(R.lensProp('programs'), event.data, context);
            }),
            showDetails: assign((context, event) => {
                return R.compose(R.set(R.lensProp('modalProgramId'), event.programId))(context);
            }),
            hideDetails: assign((context) => {
                return R.compose(R.set(R.lensProp('modalProgramId'), null))(context);
            }),
            handleError: (context, event) => {
                errorHandler(event.data);
            },
            validationError: assign({
                errors: (context, event) => {
                    toast.error('A validation error occurred.');
                    return event.data;
                },
            }),
            clearErrors: assign({
                errors: R.always(null),
            }),
        },
        services: {
            /**
             * Gets all the initial data needed to view the page.
             * @return {Promise}
             */
            getInitialData: () => {
                return Promise.all([
                    getAllPrograms({ types: 'Foundation' }),
                    getAlertUsers(),
                    CompanyService.getCompanyUsers().then(
                        R.compose(
                            R.sortBy(R.prop('fullName')),
                            R.map(
                                R.converge(R.assoc('fullName'), [
                                    R.pipe(R.props(['firstName', 'lastName']), R.join(' ')),
                                    R.identity,
                                ])
                            )
                        )
                    ),
                    getAlertSettings().then(
                        // prettier-ignore
                        R.compose(
                            R.map(R.compose(
                                R.over(R.lensProp('open'), Boolean),
                                R.over(R.lensProp('close'), Boolean))
                            ),
                        )
                    ),
                    getAlertCompanySettings(),
                ]);
            },

            validate: (context) => {
                return validationSchema.validate(context);
            },

            save: (context) => {
                /**
                 * Wrap everything in a promise so we can transition out when _any_ error happens.
                 */
                return new Promise((resolve, reject) => {
                    try {
                        // get setting
                        const userDiff = getUserDiff(context.users.original, context.users.current);
                        const settingDiff = getAlertSettingDiff(
                            context.alertSettings.original,
                            context.alertSettings.current
                        );
                        const companySettings = R.prop('companySettings', context);

                        // save them
                        resolve(
                            Promise.all([
                                // update company settings
                                updateAlertCompanySetting(companySettings),

                                // add new users
                                ...R.map(R.compose(addAlertUser, R.prop('id')), userDiff.added),

                                // remove previous users
                                ...R.map(R.compose(deleteAlertUser, R.prop('id')), userDiff.removed),

                                // update existing alerts
                                ...R.map(R.compose(updateAlertSetting, mapAlertSetting), settingDiff.changed),

                                // add new alerts
                                ...R.map(R.compose(addAlertSetting, mapAlertSetting), settingDiff.added),
                            ])
                        );
                    } catch (error) {
                        reject(error);
                    }
                });
            },
        },
    }
);

export { Events, States, stateMatchers };

export default machine;
