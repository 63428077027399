import IconWrapper from 'App/common/styles/IconWrapper';
import { useFormikContext } from 'formik';
import React, { useCallback, useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import { FaCircle, FaTimes } from 'react-icons/fa';
import { transformDbFilterToReactState } from '../workQueue.utils';
import useDeleteWorkQueueFilterMutation from '../hooks/useDeleteWorkQueueFilterMutation';
import AccordionWrapper from 'App/components/AccordionWrapper/AccordionWrapper';
import useIsPermitted from 'App/utils/hooks/useIsPermitted';
import { PERMISSION_TYPES } from 'App/utils/isPermitted';
import SessionStorageService from 'App/services/SessionStorageService';

const defaultFilterLength = 5;

const MyFilters = () => {
    const [showAllFilters, setShowAllFilters] = useState(false);
    const {
        values: {
            result: { savedFilterResult },
        },
        setFieldValue,
    } = useFormikContext();
    const deleteWorkQueueFilterMutation = useDeleteWorkQueueFilterMutation();
    const isWorkQueueFiltersEnabled = useIsPermitted(PERMISSION_TYPES.ENABLE_WORK_QUEUE_FILTERS);

    const filterList = useMemo(() => {
        return showAllFilters ? savedFilterResult : savedFilterResult.slice(0, defaultFilterLength);
    }, [savedFilterResult, showAllFilters]);

    const deleteFilter = (filterUuid, e) => {
        e.stopPropagation();
        deleteWorkQueueFilterMutation.mutateAsync({
            uuid: filterUuid,
        });
    };

    const setFilter = useCallback(
        (filterId) => {
            const selectedFilter = filterList.find((it) => it.id === filterId);
            setFieldValue('filterParams', transformDbFilterToReactState(selectedFilter?.filter));
        },
        [filterList, setFieldValue]
    );

    if (filterList.length < 1) {
        return null;
    }

    const showMoreText =
        savedFilterResult.length > defaultFilterLength
            ? `${showAllFilters ? 'Hide' : 'Show'} ${savedFilterResult.length - defaultFilterLength} more...`
            : null;
    const innerContent = (
        <div className="filter-section">
            {!isWorkQueueFiltersEnabled && (
                <span
                    aria-label="My Filters"
                    className="filter-section-header filter-section-header-saved-filters control-label"
                >
                    My Filters
                </span>
            )}
            <div className="filter form-group">
                {filterList.map((filter) => (
                    <Button
                        aria-label={filter.name}
                        block
                        bsStyle="link"
                        className="filter-record"
                        key={filter.id}
                        onClick={() => setFilter(filter.id)}
                    >
                        <FaCircle size={6} className="bullet" />
                        <span>{filter.name}</span>
                        <IconWrapper>
                            <FaTimes
                                className="text-danger delete-filter"
                                size={12}
                                onClick={(e) => deleteFilter(filter.uuid, e)}
                            />
                        </IconWrapper>
                    </Button>
                ))}
                {savedFilterResult.length > defaultFilterLength && (
                    <Button
                        aria-label={showMoreText}
                        bsStyle="link"
                        className="filter-record"
                        onClick={() => setShowAllFilters(!showAllFilters)}
                    >
                        {showMoreText}
                    </Button>
                )}
            </div>
        </div>
    );

    return (
        <div>
            {isWorkQueueFiltersEnabled ? (
                <AccordionWrapper
                    title="My Saved Filters"
                    initialVisible={SessionStorageService.getOnUser('workQueueSavedFilters-toggle', true)}
                    onClick={(newValue) => SessionStorageService.setOnUser('workQueueSavedFilters-toggle', newValue)}
                >
                    {innerContent}
                </AccordionWrapper>
            ) : (
                innerContent
            )}
        </div>
    );
};

export default MyFilters;
