import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { OverlayTrigger } from 'react-bootstrap';
import tooltipRenderer from './tooltipRenderer';

const propTypes = {
    value: PropTypes.string.isRequired,
    row: PropTypes.shape({
        original: PropTypes.shape({
            branches: PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.string.isRequired,
                    name: PropTypes.string.isRequired,
                })
            ),
            id: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
};

const defaultProps = {};

const MAX_LENGTH = 40;

function BranchesCell({ value, row }) {
    const next = R.ifElse(
        /**
         * Check if a string and is less than the max length
         */
        R.allPass([R.is(String), R.compose(R.lt(R.__, MAX_LENGTH), R.prop('length'))]),
        R.identity,
        /**
         * Drop extra values and add the ellipse
         */
        R.compose(R.concat(R.__, '...'), R.join(', '), R.dropLast(1), R.split(', '), R.slice(0, MAX_LENGTH))
    )(value);

    const user = row.original;
    const { id, branches } = user;

    return (
        <OverlayTrigger placement="top" overlay={tooltipRenderer(id, branches)} delayShow={300}>
            <span>{next}</span>
        </OverlayTrigger>
    );
}

BranchesCell.propTypes = propTypes;
BranchesCell.defaultProps = defaultProps;

export default BranchesCell;
