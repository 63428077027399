import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

/**
 * A utility for stashing the active tab value in as a query parameter so reloads and
 * refreshes will persist the active tab.
 * @param {string[]} validTabs - An array of valid tabs. All others will be ignored and the default will be used.
 * @param {string} defaultTab - The default tab for when no valid tab was found.
 * @returns {{setCurrentTab: ((function(*): void)|*), defaultActiveKey: (string|*)}}
 */
function useQueryParamTabs({ validTabs = [], defaultTab }) {
    const location = useLocation();
    const history = useHistory();

    const params = useMemo(() => {
        return new URLSearchParams(location.search);
    }, [location.search]);

    const currentTab = params.get('tab');

    const defaultActiveKey = useMemo(() => {
        if (validTabs.includes(currentTab)) {
            return currentTab;
        }
        return defaultTab;
    }, [currentTab, defaultTab, validTabs]);

    const setCurrentTab = useCallback(
        (tab) => {
            params.set('tab', tab);
            history.replace({
                ...location,
                search: `?${params.toString()}`,
            });
        },
        [history, location, params]
    );

    return {
        setCurrentTab,
        defaultActiveKey,
    };
}

export default useQueryParamTabs;
