import React from 'react';
import { AssignmentUserSelect } from './AssignmentUserSelect';
import { AssignmentActivityTable } from './AssignmentActivityTable';
import useWorkQueue from 'App/features/WorkQueue/hooks/useWorkQueue';

export const AssignmentMainSection = () => {
    useWorkQueue();

    return (
        <div>
            <AssignmentUserSelect />
            <AssignmentActivityTable />
        </div>
    );
};
