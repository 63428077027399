import { useFormikContext } from 'formik';
import { get, set } from 'lodash';
import React from 'react';
import { Button } from 'react-bootstrap';

import { NumberField } from 'Lib/form/NumberField';
import SelectField from 'Lib/form/SelectField';
import { TextField } from 'Lib/form/TextField';
import { fieldOptionsList } from '../constants';

const QueueManagerTable = () => {
    const { values, setFieldValue, setValues } = useFormikContext();
    const fieldOrderIndex = values.fieldOrders.findIndex(
        (fieldOrder) => fieldOrder.branch.division_id === values.selectedBranch.division_id
    );
    const fieldPrefix = `fieldOrders[${fieldOrderIndex}].field_order`;
    const branchFieldOrders = get(values, fieldPrefix);

    return (
        <table className="therigy-table">
            <thead>
                <tr>
                    <th id="edit-header-name-column">Edit Header Name</th>
                    <th id="information-to-display-column">Information to Display</th>
                    <th id="column-order-column">Column Order</th>
                    <th>Remove</th>
                </tr>
            </thead>
            <tbody>
                {branchFieldOrders.map((branchFieldOrder, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <tr key={index}>
                        <td>
                            <TextField
                                fieldName={`${fieldPrefix}[${index}].name_of_header`}
                                placeholder="Header Name"
                                aria-labelledby="edit-header-name-column"
                            />
                        </td>
                        <td>
                            <SelectField
                                fieldName={`${fieldPrefix}[${index}].field_selected`}
                                options={fieldOptionsList}
                                isDisabled={branchFieldOrder.required}
                                isOptionDisabled={(option) =>
                                    branchFieldOrders.find(
                                        (fieldOrder) => fieldOrder.field_selected?.value === option.value
                                    )
                                }
                                onChange={(value) => {
                                    set(values, `${fieldPrefix}[${index}].field_selected`, value);
                                    set(values, `${fieldPrefix}[${index}].name_of_header`, value.label);
                                    setValues(values);
                                }}
                                aria-labelledby="information-to-display-column"
                            />
                        </td>
                        <td>
                            <NumberField
                                fieldName={`${fieldPrefix}[${index}].field_order`}
                                aria-labelledby="column-order-column"
                                min={1}
                                onlyIntegers
                            />
                        </td>
                        <td>
                            {!branchFieldOrder.required && (
                                <Button
                                    onClick={() => {
                                        branchFieldOrders.splice(index, 1);
                                        setFieldValue(fieldPrefix, branchFieldOrders);
                                    }}
                                >
                                    Remove
                                </Button>
                            )}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default QueueManagerTable;
