import createAxios from 'App/utils/createAxios';
import convertKeysToCamelCase from 'App/utils/keyConversion/convertKeysToCamelCase';

const { protocol, host, port } = window.location;
const therigyURL = `${protocol}//${host}${port ? `:${port}` : ''}`;

const phpRequest = createAxios({
    baseURL: `${therigyURL}/app.api.php/api`,
});

export const getUsers = () => {
    return phpRequest.get('/assignment-manager/users').then(convertKeysToCamelCase);
};
