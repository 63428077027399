import React, { useEffect, useMemo, useState } from 'react';
import Table from 'App/components/Table/Table';
import propOrDash from 'App/utils/propOrDash';
import DetailsModal from 'App/components/AlertsCenter/FinancialAssistance/DetailsModal';
import { getMedicationParam, getVersionDateParam, mapSelectedFinancialProgram } from './../../referral.utils';
import removeActionCellRenderer from './removeActionCellRenderer';
import { useFormikContext } from 'formik';
import { getProgramHistory } from 'App/services/FinancialAssistanceService';
import * as R from 'ramda';

const tableStateReducer = (state, action, prevState) => {
    const { type } = action;
    const initialSort = [{ id: 'programName', desc: false }];
    switch (type) {
        case 'resetSortBy':
            return prevState;
        case 'init':
            return R.set(R.lensProp('sortBy'), initialSort, state);
        default:
            return state;
    }
};

function SelectedProgramTable() {
    const { values } = useFormikContext();
    const programId = values?.details?.programId;

    const [selectedProgram, setSelectedProgram] = useState([]);
    const [isFetchingProgram, setIsFetchingProgram] = useState(false);

    useEffect(() => {
        if (programId) {
            setIsFetchingProgram(true);
            getProgramHistory(programId, {
                drugs: getMedicationParam(R.pathOr([], ['medications'], values)),
                versionDate: values?.completedOn && getVersionDateParam(values.completedOn),
            })
                .then(R.compose(setSelectedProgram, mapSelectedFinancialProgram))
                .finally(() => setIsFetchingProgram(false));
        } else {
            setSelectedProgram([]);
            setIsFetchingProgram(false);
        }
    }, [programId, values]);

    const [showModalProgramId, setShowModalProgramId] = useState(null);

    const columns = useMemo(
        () => [
            {
                Header: 'Program/Brand',
                accessor: propOrDash('programName'),
                id: 'programName',
                disableFilters: false,
                canSort: true,
            },
            {
                Header: 'Category',
                accessor: propOrDash('therapeuticAreas'),
                id: 'therapeuticAreas',
                disableFilters: false,
                canSort: true,
            },
            {
                Header: 'Medications',
                accessor: propOrDash('drugs'),
                id: 'drugs',
                disableFilters: false,
                canSort: true,
            },
            {
                Header: 'Manufacturer',
                accessor: propOrDash('manufacture'),
                id: 'manufacture',
                disableFilters: false,
                canSort: true,
            },
            {
                Header: 'Type',
                accessor: propOrDash('assistanceType'),
                id: 'assistanceType',
                disableFilters: false,
                canSort: true,
            },
            {
                Header: 'Eligibility',
                accessor: propOrDash('eligibility'),
                id: 'eligibility',
                disableFilters: false,
                canSort: true,
            },
            {
                Header: 'Current Fund Level',
                accessor: propOrDash('foundationFundLevels'),
                id: 'foundationFundLevels',
                disableFilters: false,
                canSort: true,
            },
            {
                Header: 'Actions',
                Cell: removeActionCellRenderer(setShowModalProgramId),
            },
        ],
        []
    );

    return (
        <>
            <Table
                columns={columns}
                data={selectedProgram}
                stateReducer={tableStateReducer}
                enablePagination={false}
                isLoading={isFetchingProgram}
            />

            {showModalProgramId && (
                <DetailsModal
                    programId={showModalProgramId}
                    onHide={() => {
                        setShowModalProgramId(null);
                    }}
                />
            )}
        </>
    );
}

SelectedProgramTable.propTypes = {};
SelectedProgramTable.defaultProps = {};

export default SelectedProgramTable;
