import React from 'react';
import DateRangeFilter from './Filters/DateRangeFilter';
import BranchFilter from './Filters/BranchFilter';
import ActivityFilter from './Filters/ActivityFilter';
import TherapeuticCategoryFilter from './Filters/TherapeuticCategoryFilter';
import PredictiveModelFilter from './Filters/PredictiveModelFilter';
import SaveFilterForm from './SaveFilterForm';
import useWorkQueue from '../hooks/useWorkQueue';
import AccordionWrapper from 'App/components/AccordionWrapper/AccordionWrapper';
import useIsPermitted from 'App/utils/hooks/useIsPermitted';
import { PERMISSION_TYPES } from 'App/utils/isPermitted';
import SessionStorageService from 'App/services/SessionStorageService';

const WorkQueueFilters = () => {
    useWorkQueue();
    const isWorkQueueFiltersEnabled = useIsPermitted(PERMISSION_TYPES.ENABLE_WORK_QUEUE_FILTERS);
    const innerContent = (
        <div className="filter-section">
            {!isWorkQueueFiltersEnabled && (
                <span className="filter-section-header filter-section-header-refine-by control-label">Filter By</span>
            )}
            <DateRangeFilter />
            <BranchFilter />
            <ActivityFilter />
            <PredictiveModelFilter />
            <TherapeuticCategoryFilter />
            <SaveFilterForm />
        </div>
    );

    return (
        <div>
            {isWorkQueueFiltersEnabled ? (
                <AccordionWrapper
                    title="Filters"
                    initialVisible={SessionStorageService.getOnUser('workQueueFilters-toggle', true)}
                    onClick={(newValue) => SessionStorageService.setOnUser('workQueueFilters-toggle', newValue)}
                >
                    {innerContent}
                </AccordionWrapper>
            ) : (
                innerContent
            )}
        </div>
    );
};

export default WorkQueueFilters;
