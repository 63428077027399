import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            id: PropTypes.string.isRequired,
        }),
    }).isRequired,
    value: PropTypes.string.isRequired,
};

const defaultProps = {};

function UserLinkCell({ value, row }) {
    const user = row.original;

    return <Link to={`/user-manager/user/${user.id}`}>{value}</Link>;
}

UserLinkCell.propTypes = propTypes;
UserLinkCell.defaultProps = defaultProps;

export default UserLinkCell;
