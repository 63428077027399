import React from 'react';
import { Tooltip } from 'react-bootstrap';

function tooltipRenderer(id, branches) {
    return (
        <Tooltip id={id} placement="top">
            <div>
                {branches.length ? (
                    <ul style={{ padding: '1rem 1rem .75rem 2rem', textAlign: 'left' }}>
                        {branches.map((branch) => {
                            return <li key={branch.id}>{branch.name}</li>;
                        })}
                    </ul>
                ) : (
                    'No Branches'
                )}
            </div>
        </Tooltip>
    );
}

export default tooltipRenderer;
