import styled from '@emotion/styled';
import { useMachine } from '@xstate/react';
import { getProgramById } from 'App/services/FinancialAssistanceService';
import * as R from 'ramda';
import React, { useEffect, useRef } from 'react';
import Button from 'react-bootstrap/lib/Button';
import Modal from 'react-bootstrap/lib/Modal';
import ModalBody from 'react-bootstrap/lib/ModalBody';
import ModalFooter from 'react-bootstrap/lib/ModalFooter';
import ModalHeader from 'react-bootstrap/lib/ModalHeader';
import { assign, createMachine } from 'xstate';
import { useUserContext } from 'App/contexts/UserContext';
import { trackFaProgramSearch } from 'App/services/TrackingService';
import { errorHandler } from 'App/utils';
import TherigyLoadingSpinner from 'App/components/TherigyLoadingSpinner/TherigyLoadingSpinner';
import { createProgramDataPairs } from './utils';
import PropTypes from 'prop-types';

const propTypes = {
    onHide: PropTypes.func.isRequired,
    programId: PropTypes.number,
    showSelectBtn: PropTypes.bool,
    isSelectDisabled: PropTypes.bool,
    onSelect: PropTypes.func,
};

const defaultProps = {
    programId: null,
    showSelectBtn: false,
    isSelectDisabled: false,
    onSelect: Function,
};

const doesLabelEndWithUrl = R.compose(R.ifElse(R.is(String), R.endsWith('URL'), R.F), R.prop('label'));

const doesValueStartWithHttp = R.compose(R.ifElse(R.is(String), R.startsWith('http'), R.F), R.prop('value'));

const isLink = R.allPass([doesLabelEndWithUrl, doesValueStartWithHttp]);

const getProgramName = R.propOr('Loading...', 'ProgramName');

const FlexCenter = styled('div')`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const machine = createMachine(
    {
        id: 'detailsModal',
        initial: 'idle',
        context: {
            /**
             * The program data as delivered from the API
             */
            program: {},
            /**
             * The program data after it has been converted to key/value pairs. [ [key, value] ]
             */
            programData: [],
        },
        on: {
            FETCH: { target: 'loading', actions: ['reset'] },
        },
        states: {
            idle: {},
            loading: {
                invoke: {
                    id: 'getProgram',
                    src: 'getProgram',
                    onDone: {
                        target: 'loaded',
                        actions: ['setProgram'],
                    },
                    onError: {
                        target: 'failure',
                        actions: ['handleError'],
                    },
                },
            },
            loaded: {},
            failure: {},
        },
    },
    {
        actions: {
            setProgram: assign({
                program: (_, event) => R.prop('data', event),
                programData: (_, event) => R.compose(createProgramDataPairs, R.prop('data'))(event),
            }),
            reset: assign({
                program: R.always({}),
                programData: R.always([]),
            }),
            handleError: (context, event) => {
                errorHandler(event.data);
            },
        },
        services: {
            getProgram: (context, event) => {
                return getProgramById(event.programId);
            },
        },
    }
);

/**
 * Note: there are two modals: React and Angular. Be sure to update both
 * @see src/app/components/referral/activity-financial-assistance/program-detail-modal/program-detail-modal.html
 */
function DetailsModal({ programId, onHide, showSelectBtn, isSelectDisabled, onSelect }) {
    //#region State
    const [state, send] = useMachine(machine);

    const { program, programData } = state.context;

    const programName = R.prop('ProgramName', program);

    const hasTracked = useRef(false);

    const user = useUserContext();
    //#endregion

    //#region Side Effects
    useEffect(() => {
        if (programId) {
            send({ type: 'FETCH', programId });
        }
    }, [programId, send]);

    useEffect(() => {
        if (programName && !hasTracked.current) {
            trackFaProgramSearch(user.active_company.Name, programName);
            hasTracked.current = true;
        }
    }, [programName, user.active_company.Name]);
    //#endregion

    return (
        <Modal show bsSize="lg" backdrop="static" onHide={onHide}>
            <div className="program-detail-modal">
                <ModalHeader onHide={onHide} closeButton>
                    <h3>{programId ? getProgramName(program) : 'No program ID Provided'}</h3>
                </ModalHeader>

                <ModalBody>
                    {/* eslint-disable-next-line no-nested-ternary */}
                    {!programId ? (
                        <p>No program ID was provided. You may try again, or contact support.</p>
                    ) : // eslint-disable-next-line no-nested-ternary
                    state.matches('loading') ? (
                        <FlexCenter>
                            <TherigyLoadingSpinner />
                        </FlexCenter>
                    ) : state.matches('loaded') ? (
                        R.map((datum) => {
                            return (
                                <div className="program-data" key={`${datum.label}-label`}>
                                    <div className="col-sm-3 program-data-header">{datum.label}</div>
                                    <div className="col-sm-9 program-data-value">
                                        {isLink(datum) ? (
                                            <a href={datum.value} target="_blank" rel="noopener noreferrer">
                                                {datum.value}
                                            </a>
                                        ) : (
                                            <span>{datum.value}</span>
                                        )}
                                    </div>
                                </div>
                            );
                        }, programData)
                    ) : null}
                    {}
                </ModalBody>

                <ModalFooter>
                    <Button type="button" onClick={onHide}>
                        Close
                    </Button>
                    {showSelectBtn ? (
                        <Button
                            type="button"
                            bsStyle="primary"
                            disabled={isSelectDisabled}
                            onClick={() => onSelect(programId)}
                        >
                            Select Program
                        </Button>
                    ) : null}
                </ModalFooter>
            </div>
        </Modal>
    );
}

DetailsModal.propTypes = propTypes;
DetailsModal.defaultProps = defaultProps;

export default DetailsModal;
