import { getUsers } from 'App/services/AssignmentManagerService';
import { useQuery } from 'react-query';

function useFetchUserList() {
    return useQuery(['assignmentManagerUsers'], getUsers, {
        retry: false,
        refetchInterval: false,
        refetchOnWindowFocus: false,
    });
}

export default useFetchUserList;
