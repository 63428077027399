import PropTypes from 'prop-types';
import React from 'react';

import { patientPropType } from 'Lib/types';
import { questionPropType, scorePropType } from '../types';
import DisplayCMSContent from './DisplayCMSContent';
import { viewModes } from 'App/components/Assessments/constants';

function getBaseQuestionText(question, mode) {
    if (question) {
        switch (mode.label) {
            case viewModes.VIEW_FULLY_SCRIPTED: {
                return question.question;
            }
            case viewModes.VIEW_COMPACT: {
                return question.compactQuestion || question.question;
            }
            case viewModes.VIEW_MOBILE: {
                return question.mobileQuestion || question.question;
            }
            default:
                return question.question;
        }
    }
}

const AssessmentQuestionText = ({ question, viewMode, patient, score, showRequiredAsterisk }) => {
    const baseQuestionText = getBaseQuestionText(question, viewMode);

    return (
        <DisplayCMSContent
            content={baseQuestionText}
            patient={patient}
            score={score}
            showRequiredAsterisk={showRequiredAsterisk}
        />
    );
};

AssessmentQuestionText.propTypes = {
    question: questionPropType.isRequired,
    viewMode: PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
    }),
    patient: patientPropType,
    score: scorePropType,
    showRequiredAsterisk: PropTypes.bool,
};

AssessmentQuestionText.defaultProps = {
    patient: null,
    score: null,
    viewMode: null,
    showRequiredAsterisk: false,
};

export default AssessmentQuestionText;
