import React from 'react';
import * as R from 'ramda';
import { Button, FormGroup } from 'react-bootstrap';
import useFetchUserList from '../hooks/useFetchUserList';
import { useFormikContext } from 'formik';
import { isEmpty } from 'lodash';
import SelectField from 'Lib/form/SelectField';

export const AssignmentUserSelect = () => {
    const { data, isLoading } = useFetchUserList();
    const { values } = useFormikContext();
    const { selectedRows, assignUser } = values;
    const isSubmitEnabled = assignUser?.id && !isEmpty(selectedRows);

    return (
        <div className="assignment-manager-form">
            <FormGroup>
                <SelectField
                    isLoading={isLoading}
                    options={data}
                    isClearable
                    label="Select a user to assign activities to"
                    fieldName="assignUser"
                    getOptionValue={R.prop('id')}
                    getOptionLabel={(o) => `${o.firstName} ${o.lastName} (${o.roles.join(', ')})`}
                />
            </FormGroup>
            <Button bsClass="btn btn-primary" className="assign-tasks-btn" disabled={!isSubmitEnabled || isLoading}>
                Assign Tasks
            </Button>
        </div>
    );
};
