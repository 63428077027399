import styled from '@emotion/styled';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';

import FormButtonGroup from 'App/components/styles/FormButtonGroup';
import PESService from 'App/services/PESService';
import { stripAllHtml } from 'App/utils';
import SubmitButton from 'Lib/form/SubmitButton';
import { TextareaField } from 'Lib/form/TextareaField';
import { queryClient } from 'Lib/queryClient';
import toast from 'Lib/toast';
import { patientPropType } from 'Lib/types';
import PatientCardSectionLabel from './PatientCardSectionLabel';

const maxLength = 250;

const EditButton = styled(Button)`
    visibility: hidden;
`;

const FormStyle = styled.form`
    &:hover ${EditButton} {
        visibility: visible;
    }
`;

const LabelArea = styled.div`
    display: flex;
    justify-content: space-between;
`;

const NoteCounter = styled(PatientCardSectionLabel)`
    font-weight: normal;
`;

const PatientNotesField = styled(TextareaField)`
    resize: none;

    &[readonly] {
        cursor: pointer;
        background-color: white;
    }
`;

const PatientNotes = ({ patient }) => {
    const [isEditing, setIsEditing] = useState(false);

    return (
        <Formik
            enableReinitialize
            initialValues={{
                notes: stripAllHtml(patient.notes) || '',
            }}
            onSubmit={async (values) => {
                if (patient.id) {
                    await PESService.updatePatient(patient.id, values);
                    queryClient.setQueryData(['patient', patient.id], { ...patient, notes: values.notes });
                    toast.success('Patient notes updated successfully.');
                }
                setIsEditing(false);
            }}
        >
            {({ handleSubmit, resetForm, values }) => (
                <FormStyle onSubmit={handleSubmit}>
                    <PatientNotesField
                        fieldName="notes"
                        label={
                            <LabelArea>
                                <PatientCardSectionLabel>Patient Notes</PatientCardSectionLabel>
                                <NoteCounter aria-label="Note Character Count">
                                    {values.notes.length}/{maxLength}
                                </NoteCounter>
                            </LabelArea>
                        }
                        placeholder="Patient Notes"
                        onClick={() => setIsEditing(true)}
                        readOnly={!isEditing}
                        maxLength={maxLength}
                        rows={10}
                    />
                    <FormButtonGroup>
                        {!isEditing ? (
                            <EditButton bsSize="small" onClick={() => setIsEditing(true)}>
                                Edit
                            </EditButton>
                        ) : (
                            <>
                                <Button
                                    bsSize="small"
                                    onClick={() => {
                                        setIsEditing(false);
                                        resetForm();
                                    }}
                                >
                                    Cancel
                                </Button>
                                <SubmitButton bsSize="small">Save</SubmitButton>
                            </>
                        )}
                    </FormButtonGroup>
                </FormStyle>
            )}
        </Formik>
    );
};

PatientNotes.propTypes = {
    patient: patientPropType.isRequired,
};

export default PatientNotes;
