import React from 'react';
import ActionCell, { ActionButtonLink } from 'App/components/styles/ActionCell';

const propTypes = {};

const defaultProps = {};

function DisabledUserActionsCell() {
    return (
        <ActionCell>
            <ActionButtonLink>Enable</ActionButtonLink>
        </ActionCell>
    );
}

DisabledUserActionsCell.propTypes = propTypes;
DisabledUserActionsCell.defaultProps = defaultProps;

export default DisabledUserActionsCell;
