import React from 'react';
import ActionButtonLink from 'App/components/styles/ActionCell/ActionButtonLink';
import { Button } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { STATUS_TYPE } from '../constants';

const propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            programId: PropTypes.number.isRequired,
        }),
    }).isRequired,
};

const defaultProps = {};

const ActionCellWithoutPipes = styled('span')`
    display: flex;
    justify-content: flex-start;
    align-items: center;

    * {
        margin-right: 1rem;
    }

    .action-button-link {
        padding: 0;
    }
`;

function removeActionCellRenderer(handleShowDetails) {
    function RemoveActionCell({ row }) {
        const { initialValues, setFieldValue } = useFormikContext();

        const removeProgram = () => {
            setFieldValue('details.programId', null);
        };

        const program = row.original;

        return (
            <ActionCellWithoutPipes>
                <ActionButtonLink onClick={() => handleShowDetails(program.programId)}>Details</ActionButtonLink>
                {[STATUS_TYPE.new, STATUS_TYPE.pendingSubmission, STATUS_TYPE.submitted].includes(
                    initialValues?.status?.id
                ) && (
                    <Button bsStyle="danger" onClick={() => removeProgram(program)}>
                        Remove
                    </Button>
                )}
            </ActionCellWithoutPipes>
        );
    }

    RemoveActionCell.propTypes = propTypes;
    RemoveActionCell.defaultProps = defaultProps;

    return RemoveActionCell;
}

export default removeActionCellRenderer;
