import React from 'react';
import Page from 'App/components/Page';
import { PERMISSION_TYPES } from 'App/utils/isPermitted';
import UserManagerTabs from './UserManagerTabs';

const propTypes = {};

const defaultProps = {};

function UserManager() {
    return (
        <Page permissionType={PERMISSION_TYPES.USER_MANAGER} title="User Manager" showPageTitle>
            <UserManagerTabs />
        </Page>
    );
}

UserManager.propTypes = propTypes;
UserManager.defaultProps = defaultProps;

export default UserManager;
