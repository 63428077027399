import PropTypes from 'prop-types';
import React from 'react';
import Switch from 'react-switch';

/**
 * Wrapper component for React-Switch. See docs here
 * https://react-switch.netlify.app/
 * https://github.com/markusenglund/react-switch
 *
 * @param props
 * @return {JSX.Element}
 */
function ToggleSwitch(props) {
    return <Switch {...props} handleDiameter={16} height={15} width={40} boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6" />;
}

ToggleSwitch.propTypes = {
    onChange: PropTypes.func.isRequired,
    checked: PropTypes.bool.isRequired,
};

export default ToggleSwitch;
