import ManualPageTable from 'App/components/ManualPageTable';
import { useGetHeaderMapping } from 'App/features/WorkQueue/hooks/useGetHeaderMapping';
import { useGetWorkQueueColumns } from 'App/features/WorkQueue/hooks/useGetWorkQueueColumns';
import { setWorkQueueFilterGenerator } from 'App/features/WorkQueue/workQueue.utils';
import { useFormikContext } from 'formik';
import { isEqual } from 'lodash';
import hasLength from 'App/utils/hasLength';
import React, { useCallback, useEffect, useState } from 'react';

export const AssignmentActivityTable = () => {
    const {
        values: {
            result: { workQueueActivityCount, workQueueResult },
            filterParams,
        },
        setFieldValue,
        isSubmitting,
    } = useFormikContext();
    const setWorkQueueFilter = setWorkQueueFilterGenerator(setFieldValue);
    const headerMapping = useGetHeaderMapping();
    const { page, count, search } = filterParams;
    const [searchState, setSearchState] = useState(search || {});
    const onFilterChange = useCallback(
        (e) => {
            const prevSearch = { ...searchState };
            if (e.target.value === '') {
                delete prevSearch[e.target.name];
            } else {
                prevSearch[e.target.name] = e.target.value;
            }
            setSearchState(prevSearch);
        },
        [searchState]
    );
    const sortKey = Object.keys(filterParams?.sorting || {})[0] ?? 'patient_name';
    const sortOrder = filterParams?.sorting ? filterParams?.sorting[sortKey] : 'asc';

    useEffect(() => {
        const timer = setTimeout(() => {
            setWorkQueueFilter(filterParams, 'search', searchState);
        }, 800);
        return () => clearTimeout(timer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchState]);

    useEffect(() => {
        if (!isEqual(searchState, search)) {
            setSearchState(search);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    const columns = useGetWorkQueueColumns({
        headerMapping,
        onFilterChange,
        searchState,
        isAssignmentManager: true,
    });

    const canSort = hasLength(workQueueResult);

    const onPageChange = ({ pageIndex, pageSize }) => {
        setFieldValue('filterParams.page', pageIndex);
        setFieldValue('filterParams.count', pageSize);
    };

    const onSortChange = (sort) => {
        const sortField = sort.sortKey;
        setWorkQueueFilter(filterParams, 'sorting', {
            [sortField]: sort.sortOrder,
        });
    };

    return (
        <ManualPageTable
            data={workQueueResult}
            totalRows={workQueueActivityCount}
            placeholderText="There are no activities that match your current search criteria."
            columns={columns}
            canSort={canSort}
            isLoading={isSubmitting}
            onPageChange={onPageChange}
            onSortChange={onSortChange}
            pageSizeOptions={[10, 25, 50]}
            currentPageIndex={count}
            currentPage={page}
            initialSortKey={sortKey}
            initialSortOrder={sortOrder}
            hasSelectionRow
        />
    );
};
