const fieldOptionsLookup = {
    patient_id: 'Patient ID',
    patient_name: 'Patient Name',
    medication: 'Medication',
    activity_due_date: 'Activity Due Date',
    activity_needs_by_date: 'Activity Needs By Date',
    activity_name: 'Activity Name',
    patient_dob: 'Patient DOB',
    activity_status: 'Activity Status',
    last_updated: 'Last Updated',
    assigned_to: 'Assigned To',
    patient_labels: 'Patient Labels',
    patient_state: 'Patient State',
    branch_name: 'Branch Name',
    insurance_plan_name: 'Insurance Plan Name',
    patient_status: 'Patient Status',
    activity_status_date: 'Activity Status Last Updated Date',
    medication_association: 'Medication Association',
};

const fieldOptionsList = Object.entries(fieldOptionsLookup).map(([value, label]) => ({ value, label }));

const defaultFieldOptions = [
    {
        name_of_header: 'Patient ID',
        field_selected: {
            value: 'patient_id',
            label: 'Patient ID',
        },
        field_order: 1,
    },
    {
        name_of_header: 'Patient Name',
        field_selected: {
            value: 'patient_name',
            label: 'Patient Name',
        },
        field_order: 2,
    },
    {
        name_of_header: 'Medication',
        field_selected: {
            value: 'medication',
            label: 'Medication',
        },
        field_order: 3,
    },
    {
        name_of_header: 'Activity Due Date',
        field_selected: {
            value: 'activity_due_date',
            label: 'Activity Due Date',
        },
        field_order: 4,
    },
    {
        name_of_header: 'Activity Name',
        field_selected: {
            value: 'activity_name',
            label: 'Activity Name',
        },
        field_order: 5,
        required: true,
    },
    {
        name_of_header: 'Patient DOB',
        field_selected: {
            value: 'patient_dob',
            label: 'Patient DOB',
        },
        field_order: 6,
    },
    {
        name_of_header: 'Activity Status',
        field_selected: {
            value: 'activity_status',
            label: 'Activity Status',
        },
        field_order: 7,
        required: true,
    },
    {
        name_of_header: 'Last Updated',
        field_selected: {
            value: 'last_updated',
            label: 'Last Updated',
        },
        field_order: 8,
    },
];

const maxNumColumns = 10;

export { fieldOptionsLookup, fieldOptionsList, defaultFieldOptions, maxNumColumns };
