import React from 'react';
import ActionButtonLink from 'App/components/styles/ActionCell/ActionButtonLink';
import { Button } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            programId: PropTypes.number,
        }),
    }).isRequired,
};

const defaultProps = {};

const ActionCellWithoutPipes = styled('span')`
    display: flex;
    justify-content: flex-start;
    align-items: center;

    * {
        margin-right: 1rem;
    }

    .action-button-link {
        padding: 0;
    }
`;

function selectActionCellRenderer(handleShowDetails) {
    function SelectActionCell({ row }) {
        const { setFieldValue, values } = useFormikContext();

        const selectProgram = (program) => {
            setFieldValue('details.programId', program.programId);
        };

        const program = row.original;

        return (
            <ActionCellWithoutPipes>
                <ActionButtonLink onClick={() => handleShowDetails(program.programId)}>Details</ActionButtonLink>
                <Button disabled={!!values.details?.programId} bsStyle="primary" onClick={() => selectProgram(program)}>
                    Select
                </Button>
            </ActionCellWithoutPipes>
        );
    }

    SelectActionCell.propTypes = propTypes;
    SelectActionCell.defaultProps = defaultProps;

    return SelectActionCell;
}

export default selectActionCellRenderer;
