import React from 'react';
import { Col, Row } from 'react-bootstrap';
import DisabledUsersTable from './DisabledUsersTable';

const propTypes = {};

const defaultProps = {};

function DisabledUsers() {
    return (
        <Row data-testid="user-manager-disabled-user-tab-content">
            <Col md={12}>
                <DisabledUsersTable />
            </Col>
        </Row>
    );
}

DisabledUsers.propTypes = propTypes;
DisabledUsers.defaultProps = defaultProps;

export default DisabledUsers;
