import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React from 'react';

import ToggleSwitch from 'App/components/ToggleSwitch/ToggleSwitch';
import asFormField from './asFormField';

const TOGGLE_LABEL_PLACEMENT = Object.freeze({
    right: 'right',
    left: 'left',
});

const ToggleLabel = styled.label`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 0.2rem;

    .label-text {
        margin-left: 0.5rem;

        &.label-placement-left {
            margin-left: 0;
            margin-right: 0.5rem;
        }
    }

    .form-button-group & {
        margin-bottom: 0;
        margin-right: 1em;

        &:last-child {
            margin-right: 0;
        }
    }
`;

function LabelText({ children, placement }) {
    return <span className={`label-text label-placement-${placement}`}>{children}</span>;
}
LabelText.propTypes = {
    children: PropTypes.node.isRequired,
    placement: PropTypes.oneOf(Object.values(TOGGLE_LABEL_PLACEMENT)).isRequired,
};

function ToggleSwitchInput({
    name,
    value,
    onChange,
    onBlur,
    className,

    label,
    labelPlacement,
    disabled,
}) {
    return (
        <ToggleLabel>
            {labelPlacement === TOGGLE_LABEL_PLACEMENT.left ? (
                <LabelText placement={labelPlacement}>{label}</LabelText>
            ) : null}

            <ToggleSwitch
                id={name}
                name={name}
                checked={value}
                onChange={onChange}
                onBlur={onBlur}
                className={className}
                disabled={disabled}
            />

            {labelPlacement === TOGGLE_LABEL_PLACEMENT.right ? (
                <LabelText placement={labelPlacement}>{label}</LabelText>
            ) : null}
        </ToggleLabel>
    );
}

ToggleSwitchInput.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    className: PropTypes.string,

    label: PropTypes.node,
    labelPlacement: PropTypes.oneOf(Object.values(TOGGLE_LABEL_PLACEMENT)),
    disabled: PropTypes.bool,
};

ToggleSwitchInput.defaultProps = {
    value: false,
    onBlur: null,
    className: '',

    label: '',
    labelPlacement: TOGGLE_LABEL_PLACEMENT.right,
    disabled: false,
};

const ToggleSwitchField = asFormField({
    WrappedComponent: ToggleSwitchInput,
    suppressFieldLabel: true,
});

export { TOGGLE_LABEL_PLACEMENT };

export default ToggleSwitchField;
